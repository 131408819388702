<template>
  <v-container grid-list-xs class="pa-0 ma-0" fluid>
    <v-row class="pa-0 ma-0 titlesdark--text">
      <v-col md="8" sm="12" class="pa-0 ma-0 mt-1">
        <span class="pl-1 text-light font-size-2">{{tituloGrids}}</span>
      </v-col>
       <v-col md="12" sm="12" cols="12" class="pa-0 ma-0">
        <v-breadcrumbs
          class="py-2 px-0"
          :items="aItemsBreadcrumbs"
          divider=">"
        >
         <template v-slot:item="{ item }">
          <v-breadcrumbs-item class="titlesdark--text">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
    <v-row v-if="!mostrarFormularioNuevo" class="pa-0 ma-0">
      <v-col md="12" sm="12" class="pa-0 ma-0">
        <grid
          v-if="this.$store.state.token!=''"
          :tabla="TablaName"
          :Detalles="'DetalleElementosQR'"
          :bActiveFilter="true"
          :bActiveExport="true"
          :bSingleSelect="true"
          :bShowSelect="true"
          :sIdTable="'idELEMENTOS_QR'"
          :bShowMapDetails="false"
          :bActiveNew="false"
          :aInfoTBDetalleExp="aDetallesExport"
          @saveOk="createDetaill"
          @idSelect="getIdSelect"
          @nameField="getNameFieldSelect"
          @oItemSeleccionado="getItemSeleccionado">
            <template v-slot:slotbtnNew>
              <v-btn v-if="oPermisos.priv_insert=='1'" class="no-uppercase btn-rounded d-flex mx-2 my-1" small depressed dark  color="btndest" @click="onMostarModal">Generar QR's <v-icon right>mdi-qrcode-plus</v-icon> </v-btn>
              <v-btn class="no-uppercase btn-rounded d-flex mx-2 my-1" small depressed dark  color="btndest" @click="onExportarQRS">Exportar QR's <v-icon right>mdi-qrcode-plus</v-icon> </v-btn>
            </template>
        </grid>  
      </v-col>
    </v-row>

    <generar-qrs
      v-model="mostrarFormularioNuevo" :key="nKeyReset"
    />
    <exportar-qrs
      v-if="mostrarFormularioExportacion"
      v-model="mostrarFormularioExportacion" :key="nKeyReset"
    />
  </v-container>
</template>

<script>
import grid from '@/components/Grid/Grid.vue'
import mxFunciones from '@/mixins/funciones.js'
import GenerarQrs from '@/components/seif-modulos/control-activos/GenerarQrs.vue'
import ExportarQrs from '@/components/seif-modulos/control-activos/ExportarQrs.vue'

// import DialogFirmas from '@/components/DialogFirmas.vue'

export default {
  name: 'ControlActivosQR',
  components: {
    grid,
    GenerarQrs,
    // DialogFirmas
    ExportarQrs
  },
  mixins: [mxFunciones],
  data() {
    return {
      TablaName : 'ELEMENTOS_QR',    // Nombre de la tabla a trabajar
      nIdSeleccionado  : 0,                 // Id seleccionado
      sNombreCampoFiltro : '',                 // Nombre del campo foreignKey
      oItemSeleccionado: {},
      mostrarFormularioNuevo : false,
      mostrarFormularioExportacion: false,
      nKeyReset : 0,
      aItemsBreadcrumbs: [
        {
          text: 'Inicio',
          disabled: true,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: 'Control de Activos',
          disabled: true,
          href: 'breadcrumbs_dashboard',
        },
        {
          text: '',
          disabled: true,
          href: 'breadcrumbs_dashboard',
        },
      ],
      aDetallesExport : [],
    }
  },
  async mounted() {
    this.onValidarTokenSinLogin()
    this.tituloGrids = await this.getTitulo(this.TablaName)
    this.aItemsBreadcrumbs[2].text = this.tituloGrids
  },
  methods: {
    getIdSelect(sIdSelected) {
      this.nIdSeleccionado = sIdSelected;
    },
    getNameFieldSelect(sNameFiled) {
      this.sNombreCampoFiltro = sNameFiled;
    },
    getItemSeleccionado(oItemSeleccionado) {
      this.oItemSeleccionado = oItemSeleccionado;
    },
    createDetaill() {
  
    },
    onMostarModal() {
      this.mostrarFormularioNuevo = true
      this.nKeyReset++
      this.aItemsBreadcrumbs.push({
        text: 'Generar QRs',
        disabled: true,
        href: 'breadcrumbs_dashboard',
      })
      this.$analytics.logEvent('ControlActivosGridQr', { generarQr: 1})// CAPTURA DE DATOS ANALYTICS 
    },
    async onExportarQRS (){
     this.mostrarFormularioExportacion = true
    },
  },
  watch: {
    mostrarFormularioNuevo(bNuevoValor) {
      if(!bNuevoValor){
        this.aItemsBreadcrumbs.splice(3,1)
      }
    },
  },
  computed: {
    getIdDetalle(){
      return (this.oItemSeleccionado.tipo_registro=='Entrada') ? 'IdElemento_Entrada_IN' : 'IdElemento_Entrada_OUT'
    }
  }
}
</script>

<style lang="scss" scoped>

</style>