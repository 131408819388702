<template>
  <v-dialog v-model="value" persistent max-width="650px" v-if="value">
    <v-card min-height="300px" class="border-card">
      <v-toolbar
        color="primary"
        height="45"
        dense
        dark>
        <v-spacer></v-spacer>
        <span class="mdlHeadForms">Generar Qr's</span>
        <v-spacer></v-spacer>
        <v-btn 
          color="btncolor-dark"
          @click="CloseDialog()"
          icon
          small
          dark
          outlined>
          <v-icon size="14px">fa fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        color="headformsdark"
        height="45"
        elevation="0"
        dense
        dark>
        <span><h3 class="text-medium font-normal"><v-icon>mdi-calendar-month-outline</v-icon> Fecha de Ejecución:  {{HoraRegistro}}</h3></span>
      </v-toolbar>  
      <v-container :key="KeyRefresh">
        <v-row class="pt-3">
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
            <FormNumero
              v-model="nCantidad"
              :titulo="`Cantidad Qr's`"
              :editable="'1'"
              :sPlaceHolder="'Digite la cantidad de qrs a generar'"
              :obligatorio="true">
            </FormNumero>
          </v-col>
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
            <v-row justify="center" align="center" class="pa-4">
              <v-btn class="no-uppercase btn-rounded d-flex mx-2" :disabled="onValidarBotonDeshabilitado" large depressed dark color="btndest" @click="Save">Guardar</v-btn>
              <v-btn class="no-uppercase btn-rounded d-flex mx-2" large depressed dark color="btndest" @click="CloseDialog">Cancelar</v-btn>
            </v-row>  
          </v-col>
        </v-row>
      </v-container>    
    </v-card>
    <ErrorView v-model="bMostrarErrores" :aErrores="aErrores"></ErrorView>
    <ModalMensaje v-model="oModalMensaje.bModalMensaje" :sMensaje="oModalMensaje.sMensaje" :nTipo="oModalMensaje.nTipo" />
    <v-overlay :value="this.$bCargando">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
  </v-dialog>    
</template>

<script>
import mxFunciones from '@/mixins/funciones.js'
import HoraFecha from '@/Utlidades/Hora_Fecha.js'
import ModalMensaje from '@/components/ModalMensaje.vue'
import FormNumero from '@/components/Form/Componentes/FormNumero.vue'
import axiosServices from '@/services/axiosServices.js'
import ErrorView from '@/components/ErrorView.vue'

export default {
  name:"GenerarQrs",
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  components: {
    ErrorView,
    ModalMensaje,
    FormNumero
  },
  data() {
    return {
      nKeyModalCompromisos:0,
      nKeyModalAsistentes:0,
      bMostrarCompromisos: false,
      bMostrarAsistentes: false,
      bMostrarCompromisosLectura: false,
      oModalMensaje: {
        bModalMensaje: false,
        sMensaje: '',
        nTipo: 1
      },
      nCantidad : '',
      KeyRefresh : 0,   // empleo esta variale para refrescar los componentes
      bMostrarErrores : false,
      aErrores : [],
    }
  },
  mixins: [mxFunciones],
  mounted (){
  },
  methods: {
    CloseDialog() {
      this.$emit('input', false)
    },
    refrescar(){
      this.KeyRefresh++
    },
    async Save(){
      this.$bCargando = true
      try {
        this.validarCampos()

        // Valido que bMostrarErrores siga en falso
        if(this.bMostrarErrores){
          this.$bCargando = false
          return
        }  

        const oBody = {
          app      : 'ControlActivosGenerarQR',
          cantidad  : this.nCantidad,
          fecha    : `${this.HoraRegistro}`,
        }
        const respuesta = await axiosServices.postConfiguration('/elementos-qr/qr', oBody, true)
        this.$bCargando = false
        if(respuesta.nStatusCode==200 || respuesta.nStatusCode==220){
          this.$bCargando = false
          this.$store.dispatch('notificaciones/SetMensaje',respuesta.sMessage)
          this.$store.dispatch('notificaciones/SetColor','success')
          this.$store.dispatch('notificaciones/ShowNotificacion',true)
          this.CloseDialog()
        }else{
          this.$bCargando = false
          this.$store.dispatch('notificaciones/SetMensaje',respuesta.sMessage)
          this.$store.dispatch('notificaciones/ShowNotificacion',true)
          this.$store.dispatch('notificaciones/SetColor','error')
        }

      } catch (error) {
          this.$bCargando = false
          this.$store.dispatch('notificaciones/SetColor','error')
          this.$store.dispatch('notificaciones/SetMensaje','ERROR: GPS inactivo o No se tienen permiso para obtener la Ubicabion. Valide y refresque la Pantalla' )
          this.$store.dispatch('notificaciones/ShowNotificacion',true)
          this.bErrorPermisos = false
      }

      
    },
    f_anexos(files){
      this.aAnexos = files
    },
    FormatAnexos() {
      let aGaleria = [];
      // recorro los Anexos para sacar todos los nombres de las anexos que tenga y dejarlos como [ {Foto : "NombreArchico"}]
      this.aAnexos.forEach(Anexos => {
        // valido que no sea un file lizt con mas de un elemento 
        if(Anexos.length > 1){
          // Lo recorro de nuevo
          Anexos.forEach( Anexo => {
            aGaleria.push({ Foto : Anexo.name , TipoAnexo: (Anexo.type.includes('image')) ?'1' : '2' })
          })
        } else {
          aGaleria.push({ Foto : Anexos[0].name, TipoAnexo: (Anexos[0].type.includes('image')) ?'1' : '2'})
        }
      })

      return aGaleria

    },
    validarCampos(){
      this.aErrores = []
      this.bMostrarErrores = false

      if(this.nCantidad === '') this.aErrores.push('Debe poner una cantidad valida')
      if(this.nCantidad === '0') this.aErrores.push('Debe poner una cantidad mayor a 0')
      if(!(/^-?[\d.]+(?:e-?\d+)?$/.test(this.nCantidad))) this.aErrores.push('El formato de número es invalido')
      this.bMostrarErrores = this.aErrores.length > 0

    },
  },
  computed: {
    HoraRegistro() {
      this.refrescar()
      if(this.value){
        return HoraFecha.getNow()
      }

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
</style>