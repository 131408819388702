<template>
  <v-dialog v-model="value" persistent max-width="650px" v-if="value">
    <v-card min-height="300px" class="border-card">
      <v-toolbar
        color="primary"
        height="45"
        dense
        dark>
        <v-spacer></v-spacer>
        <span class="mdlHeadForms">Exportar Qr's</span>
        <v-spacer></v-spacer>
        <v-btn 
          color="btncolor-dark"
          @click="CloseDialog()"
          icon
          small
          dark
          outlined>
          <v-icon size="14px">fa fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-toolbar
        color="headformsdark"
        height="45"
        elevation="0"
        dense
        dark>
        <span><h3 class="text-medium font-normal"><v-icon>mdi-calendar-month-outline</v-icon> Fecha de Ejecución:  {{HoraRegistro}}</h3></span>
      </v-toolbar>  
      <v-container :key="KeyRefresh">
        <v-row class="pt-3">
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
            <FormNumero
              v-model="nInicio"
              :titulo="`ID Qr Inicial`"
              :editable="'1'"
              :sPlaceHolder="'Digite el id del primer qr a exportar'"
              :obligatorio="true">
            </FormNumero>
          </v-col>
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
            <FormNumero
              v-model="nFin"
              :titulo="`ID Qr Final`"
              :editable="'1'"
              :sPlaceHolder="'Digite el id del ultimo qr a exportar'"
              :obligatorio="true">
            </FormNumero>
          </v-col>
          <v-col sm="12" cols="12" class="py-0 ma-0 ColumnaResumida">
            <v-row justify="center" align="center" class="pa-4">
              <v-btn class="no-uppercase btn-rounded d-flex mx-2" :disabled="onValidarBotonDeshabilitado" large depressed dark color="btndest" @click="Save">Guardar</v-btn>
              <v-btn class="no-uppercase btn-rounded d-flex mx-2" large depressed dark color="btndest" @click="CloseDialog">Cancelar</v-btn>
            </v-row>  
          </v-col>
        </v-row>
      </v-container>    
    </v-card>
    <ErrorView v-model="bMostrarErrores" :aErrores="aErrores"></ErrorView>
    <ModalMensaje v-model="oModalMensaje.bModalMensaje" :sMensaje="oModalMensaje.sMensaje" :nTipo="oModalMensaje.nTipo" />
    <v-overlay :value="this.$bCargando">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
  </v-dialog>    
</template>

<script>
import mxFunciones from '@/mixins/funciones.js'
import HoraFecha from '@/Utlidades/Hora_Fecha.js'
import ModalMensaje from '@/components/ModalMensaje.vue'
import FormNumero from '@/components/Form/Componentes/FormNumero.vue'
import axiosServices from '@/services/axiosServices.js'
import ErrorView from '@/components/ErrorView.vue'

export default {
  name:"ExportarQrs",
  props: {
    value: {
      type: Boolean,
      default: false
    },
  },
  components: {
    ErrorView,
    ModalMensaje,
    FormNumero
  },
  data() {
    return {
      oModalMensaje: {
        bModalMensaje: false,
        sMensaje: '',
        nTipo: 1
      },
      nInicio : '',
      nFin: '',
      KeyRefresh : 0,   // empleo esta variale para refrescar los componentes
      bMostrarErrores : false,
      aErrores : [],
    }
  },
  mixins: [mxFunciones],
  mounted (){
  },
  methods: {
    CloseDialog() {
      this.$emit('input', false)
    },
    refrescar(){
      this.KeyRefresh++
    },
    async Save(){
      this.$bCargando = true
      try {
        this.validarCampos()

        // Valido que bMostrarErrores siga en falso
        if(this.bMostrarErrores){
          this.$bCargando = false
          return
        }  

        const aData = await axiosServices.getConfiguration('pdf/qr', false, `sTabla=ELEMENTOS_QR&nInicio=`+this.nInicio+`&nFin=`+this.nFin )
        const sFile = await this.urltoFile('data:text/pdf;base64,' + aData.aData.sFile, aData.aData.sNombreArchivo,'text/pdf')
        const url = window.URL.createObjectURL(sFile);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', aData.aData.sNombreArchivo); //or any other extension
        document.body.appendChild(link);
        link.click();

        this.$bCargando = false
        if(aData.nStatusCode==200 || aData.nStatusCode==220){
          this.$bCargando = false
          this.$store.dispatch('notificaciones/SetMensaje',"Archivo exportado")
          this.$store.dispatch('notificaciones/ShowNotificacion',true)
          this.$store.dispatch('notificaciones/SetColor','success')
          this.CloseDialog()
        }else{
          this.$bCargando = false
          this.$store.dispatch('notificaciones/SetMensaje',aData.sMessage)
          this.$store.dispatch('notificaciones/ShowNotificacion',true)
          this.$store.dispatch('notificaciones/SetColor','error')
        }

      } catch (error) {
          this.$bCargando = false
          this.$store.dispatch('notificaciones/SetMensaje','ERROR: GPS inactivo o No se tienen permiso para obtener la Ubicabion. Valide y refresque la Pantalla' )
          this.$store.dispatch('notificaciones/ShowNotificacion',true)
          this.$store.dispatch('notificaciones/SetColor','error')
          this.bErrorPermisos = false
      }

      
    },
    async onExportarQRS (){
      
    },
    urltoFile(url, filename, mimeType){
        return (fetch(url)
            .then(function(res){return res.arrayBuffer();})
            .then(function(buf){return new File([buf], filename,{type:mimeType});})
        );
    },
    validarCampos(){
      this.aErrores = []
      this.bMostrarErrores = false

      if(this.nInicio === '') this.aErrores.push('Debe poner una cantidad valida para el campo ID Qr Inicial')
      if(this.nInicio === '0') this.aErrores.push('Debe poner una cantidad mayor a 0 en el campo ID Qr Inicial')
       if(this.nFin === '') this.aErrores.push('Debe poner una cantidad valida para el campo ID Qr Final')
      if(this.nFin === '0') this.aErrores.push('Debe poner una cantidad mayor a 0 en el campo ID Qr Final')
      if(!(/^-?[\d.]+(?:e-?\d+)?$/.test(this.nFin))) this.aErrores.push('El formato de número es invalido  en el campo ID Qr Inicial')
      if(!(/^-?[\d.]+(?:e-?\d+)?$/.test(this.nFin))) this.aErrores.push('El formato de número es invalido  en el campo ID Qr Inicial')
      if(parseInt(this.nInicio) > parseInt(this.nFin)) this.aErrores.push('El QR Inicial no debe ser mayor al Final')
      if(parseInt(this.nInicio) > parseInt(this.nFin)) this.aErrores.push('El QR Inicial no debe ser mayor al Final')
      const difference = Math.abs(parseInt(this.nInicio) - parseInt(this.nFin))
      //console.log(difference);
       if(difference>1000) this.aErrores.push('No se permiten exportar mas de 1000 QRs')
      this.bMostrarErrores = this.aErrores.length > 0

    },
  },
  computed: {
    HoraRegistro() {
      this.refrescar()
      if(this.value){
        return HoraFecha.getNow()
      }

      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
